body {
    background: rgb(80,80,100);
    background: linear-gradient(140deg, rgba(80,80,100,1) 0%, rgba(0,0,50,1) 50%, rgba(0,0,36,1) 100%);
    background-attachment: fixed;
}

/* unvisited link */
a:link {
    color: red;
  }
  
  /* visited link */
  a:visited {
    color: green;
  }
  
  /* mouse over link */
  a:hover {
    color: hotpink;
  }
  
  /* selected link */
  a:active {
    color: blue;
  }

  ul {
    padding: 0;
    list-style-type: none;
}

button {
    background-color: rgb(255, 255, 255, 0);
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border: 2px solid white;
    border-radius: 10px;
    transition: all .2s ease-in-out;
    cursor: pointer
  }

button:hover {
    background-color: rgb(255, 255, 255, 0.2);
    transform: scale(1.025)
}

.mainitem {
    color: white;
    background: rgb(255, 255, 255, 0.15);
    padding: 20px;
    margin: 20px;
    z-index: 3;
}

.mainitem h2 {
    text-align: center;
}

.mainitem-2 {
    opacity: 1;
    color: white;
    padding: 20px;
    margin: 20px;
    z-index: 3;
}

.mainitem-2 h2 {
    text-align: center;
}

/* desktop view */
@media only screen and (min-width: 992px) {
    #about {
        height: 88vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }  

    .about-images {
        width: 35%;
        height: auto;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .about-text {
        padding: 10px;
        width: 45%
    }
    
    #portrait {
        object-fit: contain;
        flex-basis: auto;
        max-width: 100%;
        height: auto;
        align-self: flex-start;
        border-radius: 50%;
        padding: 10px
    }

    .thumbnail {
        display: block;
        width: 50px;
        height: auto;
        padding: 10px;
        transition: all .2s ease-in-out;
    }

    .thumbnail:hover {
        transform: scale(1.1)
    }

    #skills ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        /* flex-flow: column wrap; */
        justify-content: center;
        align-items: center;
    }
    
    #skills li {
        font-size: 22px;
        list-style-type: none;
        padding: 10px;
        margin: 10px;
    }
    
}

/* mobile view */
@media only screen and (max-width: 991px) {
    #about {
        height: 88vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
    }  

    .about-images {
        width: 65%;
        height: auto;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .about-text {
        width: 75%;
        padding: 10px;
    }

    .about-text h1 {
        font-size: 8vw;
    }

    
    #portrait {
        object-fit: contain;
        flex-basis: auto;
        max-width: 100%;
        height: auto;
        align-self: flex-start;
        border-radius: 50%;
        padding: 10px
    }

    .thumbnail {
        display: block;
        width: 25px;
        height: auto;
        padding: 10px;
        transition: all .2s ease-in-out;
    }

    .thumbnail:hover {
        transform: scale(1.1);
    }

    #skills ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        /* flex-flow: column wrap; */
        justify-content: center;
        align-items: left;
    }
    
    #skills li {
        font-size: 16px;
        list-style-type: none;
        padding: 5px;
    }
    
}

.portfoliocardholder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.portfoliocard {
    background: rgb(255, 255, 255, 0.15);
    color: white;
    /* border: 5px solid white; */
    border-radius: 10px;
    width: 300px;
    max-width: 90%;
    padding: 10px;
    margin: 10px;
    transition: all .2s ease-in-out;
    cursor: pointer
}

.portfoliocard img {
    max-width: 100%;
    height: auto;
}

.portfoliocard h4 {
    text-align: center;
}

.portfoliocard:hover {
    transform: scale(1.05)
}

.portfoliocard ul {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
}

.portfoliocard li {
    padding: 5px;
    width: fit-content;
}

.card-header {
    position: sticky;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    overflow: scroll;
}

.carditem {
    color: white;
    background: rgb(255, 255, 255, 0.1);
    padding: 20px;
    /* margin: 20px; */
    width: 100%;
    align-self: center;
}

.carditem h2 {
    text-align: center;
}

.carditem p {
    margin: 20px;
}

.carditem-2 {
    color: white;
    background: rgb(255, 255, 255, 0.05);
    /* background: rgb(0,88,176);
    background: linear-gradient(140deg, rgba(0,88,176,1) 0%, rgba(0,0,128,1) 50%, rgba(0,0,32,1) 100%); */
    padding: 20px;
    /* margin: 20px; */
    width: 100%;
    align-self: center;
}

.carditem-2 h2 {
    text-align: center;
}

.carditem p {
    margin: 20px;
}

#card-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#close-button {
    top: 0;
    right: 0;
    position: fixed;
    display: inline-block;
    width: 25px;
    height: auto;
    padding: 10px;
    transition: all .2s ease-in-out;
    cursor: pointer
}

#close-button:hover {
    transform: scale(1.1)
}



/* desktop */
@media only screen and (min-width: 992px) {
    .bigportfoliocard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 80%;
        max-width: 80%;
        background: rgb(80,80,100);
        background: linear-gradient(140deg, rgba(80,80,100,1) 0%, rgba(0,0,50,1) 50%, rgba(0,0,36,1) 100%);
        color: white;
        border: 5px solid white;
        border-radius: 10px;
        overflow: hidden;
        z-index: 1000;
    }
    
    .bigportfoliocard h3, h4 {
        text-align: center;
    }

    .bigportfoliocard ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        /* flex-flow: column wrap; */
        justify-content: center;
        align-items: center;
    }
    
    .bigportfoliocard li {
        font-size: 18px;
        list-style-type: none;
        padding: 8px;
    }
}

/* mobile */
@media only screen and (max-width: 991px) {
    .bigportfoliocard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 80%;
        min-width: 90%;
        max-width: 90%;
        background: rgb(80,80,100);
        background: linear-gradient(140deg, rgba(80,80,100,1) 0%, rgba(0,0,50,1) 50%, rgba(0,0,36,1) 100%);
        color: white;
        border: 5px solid white;
        border-radius: 10px;
        /* padding: 10px; */
        overflow: hidden;
        z-index: 1000;
    }

    .bigportfoliocard h3, h4 {
        text-align: center;
    }

    .bigportfoliocard ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        /* flex-flow: column wrap; */
        justify-content: center;
        align-items: left;
    }
    
    .bigportfoliocard li {
        font-size: 16px;
        list-style-type: none;
        padding: 5px;
    }
}

#contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.ffvii {
    color: white;
    background: rgb(0,88,176);
    background: linear-gradient(140deg, rgba(0,88,176,1) 0%, rgba(0,0,128,1) 50%, rgba(0,0,32,1) 100%);
    border-radius: 10px;
    border: 5px solid white;
}